export default {
  familyWebsiteListState(state) {
    return state.list;
  },
  familyWebsiteListLoadingState(state) {
    return state.listLoading;
  },
  familyWebsiteDetailsState(state) {
    return state.details;
  },
  familyWebsiteDetailsLoadingState(state) {
    return state.detailsLoading;
  },
  allFamilyWebsitesListSettingsState(state) {
    return state.allSitesListSettings || [];
  },
  allFamilyWebsitesListSettingsLoadingState(state) {
    return state.allSitesListSettingsLoading;
  },
  familyWebsiteSettingsLoadingState(state) {
    return state.updateLoading;
  },
  familyWebsiteAddMembersLoadingState(state) {
    return state.addMembersLoading;
  },
  familyWebsiteDeleteMemberLoadingState(state) {
    return state.deleteMembersLoading;
  },
};
