export default {
  setFamilyWebsiteListState(state, list) {
    state.list = list;
  },
  setFamilyWebsiteListLoadingState(state, loading) {
    state.listLoading = loading;
  },
  setFamilyWebsiteDetailsState(state, details) {
    state.details = details;
  },
  setFamilyWebsiteDetailsLoadingState(state, loading) {
    state.detailsLoading = loading;
  },
  setAllFamilyWebsitesListSettingsState(state, data) {
    state.allSitesListSettings = data;
  },
  mutateAllFamilyWebsitesListSettingsState(state, {username, is_public, site_members}) {
    const data = state.allSitesListSettings.map(item => {
      if (item.username !== username) {
        return item;
      }
      const newSites = item.sites.map(site => {
        const newIsPublic = typeof is_public == 'boolean' ? is_public : site.is_public;
        return {...site, is_public: newIsPublic};
      });
      const newSiteMembers = Array.isArray(site_members) ? site_members : item.site_members;
      return {...item, sites: newSites, site_members: newSiteMembers};
    });
    state.allSitesListSettings = [...data];
  },
  setAllFamilyWebsitesListSettingsLoadingState(state, loading) {
    state.allSitesListSettingsLoading = loading;
  },
  setFamilyWebsiteSettingsLoadingState(state, {username, loading}) {
    state.updateLoading = {...state.updateLoading, [username]: loading};
  },
  setFamilyWebsiteAddMembersLoadingState(state, {username, loading}) {
    state.addMembersLoading = {...state.addMembersLoading, [username]: loading};
  },
  setFamilyWebsiteDeleteMemberLoadingState(state, {email, loading}) {
    state.deleteMembersLoading = {...state.deleteMembersLoading, [email]: loading};
  },
};
