import {getRoutePageName} from '@common/utils/analytics/utils.analytics';
import {TAB_ID_INDEXED_RECORDS} from '@common/utils/consts.search';
import {slugify} from '@common/utils/utils';
import {getPrimaryEnCnNames} from '@common/utils/utils.names';
import {hasClassifiedData} from '@common/utils/utils.sources';
import pickBy from 'lodash/pickBy';

export function getRouteNameByUrl(url, router) {
  const resolved = router.resolve(url);
  return resolved && resolved.route && resolved.route.name ? getRoutePageName(resolved.route) : '';
}

export function getPlaceDetailRoute(id, name) {
  const params = {placeId: id};
  const slug = slugify(name);
  if (slug) {
    params.slug = slug;
  }
  return {name: 'place-detail', params};
}

export function getMigrationPlaceDetailRoute(id, name) {
  const params = {placeId: id};
  const slug = slugify(name);
  if (slug) {
    params.slug = slug;
  }
  return {name: 'place-detail', params};
}

export function getCemeteryDetailRoute(id, name) {
  const params = {id};
  const slug = slugify(name);
  if (slug) {
    params.slug = slug;
  }
  return {name: 'cemetery-detail', params};
}

export function getPersonRecordDetailRoute(code, name) {
  const params = {code};
  const slug = slugify(name);
  if (slug) {
    params.slug = slug;
  }
  return {name: 'person-records-detail', params};
}

export function getSearchByProfileFilters(person) {
  const [fnEn, fnCn] = getPrimaryEnCnNames(person.first_names);
  const [snEn, snCn] = getPrimaryEnCnNames(person.surnames);
  const first_name = [fnEn, fnCn].filter(n => !!n).join(', ');
  const surname = [snEn, snCn].filter(n => !!n).join(', ');
  const birth_location_id =
    person.birth_location && person.birth_location.place_id ? person.birth_location.place_id.toString() : undefined;
  const death_location_id =
    person.death_location && person.death_location.place_id ? person.death_location.place_id.toString() : undefined;
  const birth_year = person.birth_date && person.birth_date.year ? person.birth_date.year.toString() : undefined;
  const death_year = person.death_date && person.death_date.year ? person.death_date.year.toString() : undefined;

  let filters = {
    first_name,
    surname,
    birth_location_id,
    death_location_id,
    birth_year,
    death_year,
  };

  if (person.residence_location && person.residence_location.length && person.residence_location[0].place_id) {
    const {place_id, from_year, to_year} = person.residence_location[0];
    filters.residence_location = place_id;
    filters.residence_from = from_year;
    filters.residence_to = to_year;
  }

  const values = Object.values(filters);
  if (values.some(value => hasClassifiedData(value))) {
    return;
  }
  if (values.some(value => !!value)) {
    return {...filters, is_flexible: 'true'};
  }
}

export function getSearchByProfileRoute(person) {
  const filters = getSearchByProfileFilters(person);
  if (filters) {
    const query = {
      results: 'true',
      tab: TAB_ID_INDEXED_RECORDS,
      ...filters,
    };
    return {name: 'search-all-records', query};
  }
}

export function getSubscriptionWallRoute(redirect, recordTitle, fromHints = false) {
  let query = {redirect: redirect, record_title: recordTitle, hints: fromHints};
  return {name: 'subscription-plans', query: pickBy(query, value => !!value || value === '')};
}

export function getSurnameDetailRoute(surname) {
  return {name: 'search-tool-surname-detail', query: {word: surname}};
}

export function getDialectDetailRoute(slug) {
  return {name: 'dialect-detail', params: {slug}};
}

export function getFamilyTreeRoute(store, query) {
  if (store.getters.userIsLoggedInState && !store.getters.userIsSharedState && store.getters.activeFamilyTreeIdState) {
    return {name: 'familytree-details', params: {id: store.getters.activeFamilyTreeIdState}, query};
  }
  return {name: 'familytree-details-my', query};
}

export function getFamilyTreeLibraryRoute(store, query) {
  if (store.getters.userIsLoggedInState && !store.getters.userIsSharedState && store.getters.activeFamilyTreeIdState) {
    return {name: 'familytree-library', params: {id: store.getters.activeFamilyTreeIdState}, query};
  }
  return {name: 'familytree-library-my', query};
}

export function getHintDetailRoute(hintId) {
  return {name: 'hint-review', params: {hintId: hintId}};
}

export function getTreeClanDetailRoute(treeId, clan) {
  return {name: 'familytree-clan-details', params: {id: treeId, name: clan}};
}

export function getSourceCollectionRoute(collection) {
  return {name: 'collection-detail', params: {id: collection.pk}};
}

export function isValidRedirectPath(path) {
  return path && !path.includes('prerender');
}
