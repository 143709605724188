import network from '@common/network/network';

export default {
  fetchFamilyWebsiteListAction(context, username) {
    context.commit('setFamilyWebsiteListLoadingState', true);
    return new Promise((resolve, reject) => {
      network.fws
        .getFamilyWebSiteList({limit: 9999, offset: 0, username: username})
        .then(response => {
          context.commit('setFamilyWebsiteListState', response.objects);
          resolve(response.objects);
        })
        .finally(() => {
          context.commit('setFamilyWebsiteListLoadingState', false);
        });
    });
  },
  fetchFamilyWebsiteDetailsAction(context, {username, siteName, partKey, keepImageAssetId = ''}) {
    context.commit('setFamilyWebsiteDetailsLoadingState', true);
    return new Promise((resolve, reject) => {
      network.fws
        .getFamilyWebSiteListByName({
          username,
          site_name: siteName,
          part: [partKey, 'glossary'],
          keep_image_asset_id: keepImageAssetId,
        })
        .then(response => {
          context.commit('setFamilyWebsiteDetailsState', response);
          resolve(response);
        })
        .catch(reject)
        .finally(() => {
          context.commit('setFamilyWebsiteDetailsLoadingState', false);
        });
    });
  },
  fetchAllMyFamilyWebsitesSettingsAction(context) {
    context.commit('setAllFamilyWebsitesListSettingsLoadingState', true);
    return new Promise((resolve, reject) => {
      network.fws
        .getMySites()
        .then(response => {
          context.commit('setAllFamilyWebsitesListSettingsState', response.objects);
          resolve(response);
        })
        .catch(reject)
        .finally(() => {
          context.commit('setAllFamilyWebsitesListSettingsLoadingState', false);
        });
    });
  },
  updateMyFamilyWebsiteSettingsAction(context, {username, is_public}) {
    context.commit('mutateAllFamilyWebsitesListSettingsState', {username, is_public});
    context.commit('setFamilyWebsiteSettingsLoadingState', {username, loading: true});
    return new Promise((resolve, reject) => {
      network.fws
        .updateMySite({username, is_public})
        .then(response => {
          resolve(response);
        })
        .catch(reject)
        .finally(() => {
          context.commit('setFamilyWebsiteSettingsLoadingState', {username, loading: false});
        });
    });
  },
  familyWebsiteAddMembersAction(context, {username, emails}) {
    context.commit('setFamilyWebsiteAddMembersLoadingState', {username, loading: true});
    return new Promise((resolve, reject) => {
      network.fws
        .addMembers({username, emails})
        .then(resolve)
        .catch(reject)
        .finally(() => {
          context.commit('setFamilyWebsiteAddMembersLoadingState', {username, loading: false});
        });
    });
  },
  familyWebsiteDeleteMemberAction(context, {username, email}) {
    context.commit('setFamilyWebsiteDeleteMemberLoadingState', {email, loading: true});
    return new Promise((resolve, reject) => {
      network.fws
        .deleteMember({username, email})
        .then(resolve)
        .catch(reject)
        .finally(() => {
          context.commit('setFamilyWebsiteDeleteMemberLoadingState', {email, loading: false});
        });
    });
  },
};
