export default {
  list: null,
  listLoading: false,
  details: null,
  detailsLoading: null,
  allSitesListSettings: [],
  allSitesListSettingsLoading: false,
  updateLoading: {},
  addMembersLoading: {},
  deleteMembersLoading: {},
};
