export default {
  surnamesRegisterState(state) {
    // ToDo: investigate why surnames is sometimes undefined?
    const surnames = state.surnames || [];
    return surnames.filter(surname => !!surname);
  },
  registerReferralDataState(state) {
    return state.registerReferralData;
  },
};
