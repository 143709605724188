import network from '@common/network/network';

export default {
  sendSupportFormAction(context, {email, firstName, lastName, comment, help_type, attachments}) {
    context.commit('mutateSupportFormLoadingState', true);
    return new Promise((resolve, reject) => {
      const data = {
        email,
        first_name: firstName,
        last_name: lastName,
        comment,
        help_type,
        attachments,
      };
      network.prospectRequest
        .sendSupportFormMCR(data, progressRes => {
          const value = ((progressRes.loaded / progressRes.total) * 100).toFixed(2);
          context.commit('mutateSupportFormLoadingProgressState', value);
        })
        .then(resolve)
        .catch(reject)
        .finally(() => {
          context.commit('mutateSupportFormLoadingState', false);
        });
    });
  },
};
